<template>
    <div>
        <v-card :loading="poiSearchLoading">
            <v-toolbar flat color="transparent">
                <v-toolbar-title>
                    Manage {{ type }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn text @click="$emit('close')">X</v-btn>
            </v-toolbar>
            <v-row class="mx-1 my-2">
                <v-chip outlined small class="mx-1 mt-1" v-for="(poi, index) in poiFunctions" :key="index"
                    :color="poi.selected ? 'teal' : ''" @click="selectFunction(poi)">
                    <v-icon v-if="poi.selected" small left>check</v-icon>
                    {{ poi.name }}
                </v-chip>
            </v-row>
            <v-card-text>
                <v-text-field hide-details outlined dense rounded clearable v-model="poiSearch" placeholder="Search"
                        prepend-inner-icon="search"></v-text-field>
                <v-list dense style="max-height: 50vh; overflow-y: auto;">
                    <v-list-item v-for="point in pointsOfInterest" :key="point.id" @click="$emit('setPoint', point)">
                        <v-list-item-content>
                            <v-list-item-title>{{
                                point.name
                            }}</v-list-item-title>
                            <v-list-item-subtitle style="font-size: 12px" class="text-wrap">
                                {{ point.address }}</v-list-item-subtitle>
                            <v-list-item-subtitle v-if="point.poiFunctions && point.poiFunctions.length > 0">
                                <v-chip class="mx-1" x-small v-for="functionality in point.poiFunctions"
                                    :key="functionality.id">{{ functionality.functionality }}</v-chip>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
export default {
    props: ['type', 'item', 'poiFunctions'],
    data: () => ({
        loading: false,
        pointsOfInterest: [],
        poiParams: {
            limit: 50,
            offset: 0,
            search: null
        },
        poiSearch: null,
        poiSearchLoading: false,
        poiSearchTimeout: null,
        poiRequest: null,
    }),
    watch: {
        'poiSearch': {
            immediate: true,
            handler(val) {
                if (val) {
                    this.searchPois()
                }
            }
        },
    },
    created() {
        this.searchPois()
    },
    methods: {
        selectFunction(poiFunction) {
            this.poiFunctions.forEach(poi => {
                poi.selected = false
            });
            poiFunction.selected = true
            this.searchPois()
        },
        searchPois() {
            if (this.poiSearchLoading) {
                clearTimeout(this.poiSearchTimeout);
            }
            if (this.poiRequest) {
                this.poiRequest.abort()
            }
            this.poiParams.filter = this.poiFunctions.filter(item => item.selected).map(item => item.name)
            this.poiParams.search = this.poiSearch
            this.poiSearchLoading = true;
            this.poiSearchTimeout = setTimeout(async () => {
                this.poiRequest = new AbortController();
                const signal = this.poiRequest.signal
                this.pointsOfInterest = await this.$API.getPois(
                    {
                        params: this.poiParams,
                        signal
                    }
                );
                this.poiSearchLoading = false;
            }, 500);
        }

    }
}
</script>